import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SchematService } from 'src/app/service/schemat.service';
import { Schemat, SchematChange, ChangeAction } from 'src/app/schemat';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-schemat-form',
  templateUrl: './schemat-form.component.html',
  styleUrls: ['./schemat-form.component.css']
})
export class SchematFormComponent implements OnInit {

  id: String;
  schemat: Schemat;
  formGroup: FormGroup;
  titleAlert: string = 'This field is required';
  possibleSchemats : Schemat[];


  constructor(private route: ActivatedRoute,
    private router: Router,
    private schematService: SchematService,
    private formBuilder: FormBuilder) { }
    

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.schemat = this.schematService.getById(this.id)
    });
    this.schematService.retrieveByLoggedInUser().then(summaries=> {
      this.possibleSchemats = summaries as Schemat[];
    });

    this.createForm();
    this.setChangeValidate();
  }

  setChangeValidate() {
   }
 
   createForm() {
     this.formGroup = this.formBuilder.group({
       'name': [this.schemat.name, Validators.required],
       'version': [this.schemat.version, Validators.required],
     });
   }
 
   get name() {
    return this.formGroup.get('name') as FormControl
  }

  get version() {
    return this.formGroup.get('version') as FormControl
  }


  addImport() {
    if (this.possibleSchemats.length>0) {
        const existingImports = (this.schemat.imports==null) ? [] : this.schemat.imports;
        existingImports.push(this.possibleSchemats[0]);
        this.schemat.imports = existingImports;
    }
  }

  deleteImport(index: number) {
  this.schemat.imports.splice(index, 1)
  }

  apply() {

    const change: SchematChange = { id: this.id, _a:ChangeAction.Update } 
    var changed = false;

    const newversion = this.formGroup.value['version']
    const newname = this.formGroup.value['name']
    if (this.schemat.name != newname /*|| this.schemat.version != newversion*/ ) {
        change.name = newname;
        changed = true 
    }
    if (this.schemat.version != newversion /*|| this.schemat.version != newversion*/ ) {
      change.version = newversion;
      changed = true 
  }
  if (changed ) {
      this.schematService.change(change) 
    } 

  }

  back() {
    this.router.navigate(['editor', this.id ])
  }

  save() {
    this.apply()
    this.back() 
  }

  async snapshot() {
    await this.schematService.createsnapshot(this.id, this.formGroup.value['version']).then(
      snapshotId => {
        console.log("snapshot snapshotId:", snapshotId)
        this.router.navigate(['all' ])
        //this.id=snapshotId
      }
    )
  }


  cancel() {
     this.back()
  }


}
