import { Injectable } from '@angular/core';
import {
  Schemat, Change, SchematChange,
  Entity, EntityChange, Connection, Cardinality, ConnectionType, ConnectionChange, EntityView, EntityViewChange, ChangeAction, Role, RoleChange, RoleView, RoleViewChange, ViewPosition, PathChange, Path, PathViewChange, PathView, PathSegment, PathSegmentChange
} from '../schemat'
import { RectangleBounds, Point } from '../schemat-externals'
import {
  getEntityById, getEntityViewById, getConnectionById, findEntityImportById, getRoleById,
  getRoleViewById, getPathViewById, getPathById, getConnectionByIdSearchimports, getAllPathSegments
} from '../schemat-read-util'

import {newid} from '../schemat-util'
import {SchematSummary} from '../schemat-read-util'


//import { removeAndTagRepeatedRefs, restoreRepeatedRefs } from '../serialisationutil'
import { defaultSchemat, importedEntityPlaceholderName, apply, resolveImportLinks, rebuildSchemat, resolveIds} from '../schemat-changeprocessor' 
//import withToken from './cognito-utils'
import { API } from 'aws-amplify'

import Auth from '@aws-amplify/auth';
import { isArray } from 'util';

@Injectable({
  providedIn: 'root'
})
export class SchematService {

  constructor() { }

  schemats: Schemat[] = []

  private add(schemat: Schemat) {
    this.schemats.push(schemat)
  }


  //TODO make this async
  getById(id: String): Schemat {
    var result = null
    this.schemats.forEach(
      (s) => {
        if (id == s.id) {
          result = s
        }
      }
    )
    return result
  }

  createSchematChange(id?: String): SchematChange {
    if (id == null) {
      return { id: newid(), _a: ChangeAction.Insert }//bootstrapSchemat(newid())
    }
    return { id: id, _a: ChangeAction.Insert }
  }


  change(change: SchematChange, id?: String) {
    var schemat = this.getById(change.id)
    if (schemat == null) {
      change.id = newid()
      schemat = defaultSchemat(change.id)
      this.add(schemat)
      //schemat = new Schemat()
    }
    console.log("change apply ing" )
    apply(schemat, change)
    console.log("change applied ing" )

    //removeAndTagRepeatedRefs(change)
    let strEvent = JSON.stringify(change)
    console.log("strEvent " + strEvent )
    //restoreRepeatedRefs(change)
    let startTime = Date.now()
    this.saveSchematChange(strEvent, schemat.id, schemat.name, schemat.version).then(
      (result) => console.log("posted event in " + (Date.now() - startTime))
    )
  }

  async saveSchematChange(strEvent: String, id: String, name: String, version?: String) {
    const apiName = "schemactCrudfunction";
    const path = "/save";
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: { id: id, name: name, event: strEvent, version: version }
    };

    return await API.post(apiName, path, myInit);
  }

  async createsnapshot(id: String, version: String) {
    const snapshotId = newid()
    const apiName = "schemactCrudfunction";
    const path = `/createSnapshot/${id}/${snapshotId}/${version}`;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    };

    return await API.post(apiName, path, myInit).then(results => {
      return snapshotId;
    })
  }

  async retrieveByLoggedInUser(): Promise<SchematSummary[]> {
    const apiName = "schemactCrudfunction";
    const path = "/retrieveByLoggedInUser";
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    };

    return await API.get(apiName, path, myInit);
  }

  async retrieveByIdResolveImports(id: String): Promise<Schemat> {
    //TODO avoid trying to resolve external imports
    // or resolve import changes first !

    var schemat = await this.retrieveById(id);
    const autoresolved  = true
    if (autoresolved) return schemat

    if (schemat.imports != null) {
      console.log("imports: ", schemat.imports);
      for (var index = 0; schemat.imports && index < schemat.imports.length; index++) {
        var localIndex = index;
        const importId = schemat.imports[localIndex].id;
        console.log("loading import:" + importId);
        const dependency = await this.retrieveById(importId as unknown as string)
        console.log("loaded import:" + importId + " at index " + localIndex);
        schemat.imports[localIndex] = dependency;
        console.log("setting import:" + importId + ":" + dependency.name + " at index " + localIndex + " in " + schemat.id + ":" + schemat.name);
      }

      resolveImportLinks(schemat)

    }
    return schemat
  }


  async retrieveById(id: String): Promise<Schemat> {
    const apiName = "schemactCrudfunction";
    //    const path =  `/retrieveById/${id}`;  
    const path = `/currentSnapshotById/${id}`;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }/*,
      queryStringParameters: {  // OPTIONAL
        id: id,
    },*/
    };

    var resultPromise = await API.get(apiName, path, myInit).then(
      (result) => {
        resolveIds(result)

        //if (!isArray(result)) {
        //  result = [result]
        //}
        const schemat: Schemat = result;//rebuildSchemat(result, id)

        //TODO deal with this hack !
        schemat.entityViews = schemat.entityViews.map( (ev) => 
            {return new EntityView(ev.id, ev.entity, ev.x, ev.y, ev.width, ev.height)}   )
        schemat.roleViews = schemat.roleViews.map( (rv) => 
            {return new RoleView(rv.id, rv.role, rv.position)}   )
        schemat.pathViews = schemat.pathViews.map( (pv) => 
            {return new PathView(pv.id, pv.path, pv.position)}   )
        //TODO hack !
        if (!schemat.viewPort || !schemat.viewPort.width || !schemat.viewPort.height) {
          schemat.viewPort= new RectangleBounds(0, 0, 1000, 1000)
        }


        // TODO load externals here 
        console.log("retrieveById loaded x ", schemat);
        this.schemats.push(schemat);
        return schemat
      }
    )

    return resultPromise;
  }

 
}
